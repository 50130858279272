import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { makeStyles } from "tss-react/mui";

import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";
import mobileBackground from "@hey-lady/shared/static/images/referral-background-mobile.svg";
import background from "@hey-lady/shared/static/images/referral-background.svg";
import Button from "@hey-lady/ui/atoms/Button.tsx";
import Typography from "@hey-lady/ui/atoms/Typography.tsx";
import IconLogo from "@hey-lady/ui/icons/IconLogo.tsx";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

/**
 * Styles
 */
const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "100vh",
    backgroundImage: `url("${background}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: theme.spacing(Spacing.l),
    paddingLeft: theme.spacing(Spacing.xxxl),
    paddingRight: theme.spacing(Spacing.xxxl),
    [theme.breakpoints.down("lg")]: {
      paddingTop: theme.spacing(Spacing.l),
      paddingLeft: theme.spacing(Spacing.xxl),
      paddingRight: theme.spacing(Spacing.xxl),
    },
    [theme.breakpoints.down("sm")]: {
      backgroundImage: `url("${mobileBackground}")`,
      backgroundSize: "contain",
      backgroundPositionY: "40px",
      padding: theme.spacing(Spacing.ml),
    },
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    marginTop: theme.spacing(Spacing.l),
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  logo: {
    width: "147px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ReferralLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.logoContainer}>
        <IconLogo color="primary" className={classes.logo} />
        <Link to="/auth/login">
          <Button variant="outlined" color="primary" size="small">
            <Typography color="primary" variant="body2" weight={FontWeight.Bold}>
              <FormattedMessage id="AUTH_LOG_IN" />
            </Typography>
          </Button>
        </Link>
      </Box>
      <Box className={classes.container}>{children}</Box>
    </Box>
  );
};

export default ReferralLayout;
